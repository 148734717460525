.App {
  text-align: center;
}
.sticky-header {
  position: sticky;
  top: 0; /* Stick the header to the top of the viewport */
  z-index: 1000; /* Optional: Adjust the z-index to ensure the header is above other content */
 /* Optional: Add background color to the header */
 
}
.headersnew{
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  }

body{
}
a{
  text-decoration: none;
  color: inherit;
}
a:hover{
  text-decoration: none;
  color: inherit;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.article
{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 30px;
   -webkit-box-shadow: 0 6px 10px rgba(0,0,0,0.04);
   -moz-box-shadow: 0 6px 10px rgba(0,0,0,0.04);
  box-shadow: 0 6px 10px rgba(0,0,0,0.5);
  width: 540px;
}
.no
{
  display: flex;

  align-items: center;
  box-shadow: 0 6px 10px rgba(0,0,0,0.5);
  border-radius: 15px;
  width:300px
}
.articleHead{
  display: flex;
  justify-content: space-around;
 
}
.marquee-container {
  overflow: hidden;
}

.marquee {
  display: flex;
  margin: 30px;
  animation: marqueeAnimation 20s linear infinite;
}
.marqueei {
  display: flex;
  margin: 30px;
  animation: marqueeiAnimation 20s linear infinite;
}
.cardDisease{
  display: inline-table;
  border-radius: 20px;
  width:320px;
  margin-right: 60px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
.cardDoctor{
  display: flex;
  flex-direction: column;
 align-items: center;
  border-radius: 20px;
  width:auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.cardDoctor img:hover{
  transform: scale(1.05);
  transition: 0.25s ease;
  
}
.doctorDiv{
  display:flex;
  justify-content: space-evenly;
}
@keyframes marqueeAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes marqueeiAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.diseaseContainer{ 
display: flex;
flex-wrap: nowrap;
flex: content;
margin-bottom: 40px;

}
.headers{
font-size: 50px;
font-weight: bolder;
text-align: center;


}
.heading{
  padding-top: 5rem;
padding-bottom: 5rem;
}
.diseaseName{
  text-align: center;
  padding-top:5px;
}
imgi:hover{
 transform: scale(110%);

}
.imgi{
  margin:5px;
  width: 270px;
height: 240px;
}
.cardDisease img {
  border-radius: 25px;
  transition: opacity 0.3s ease; /* Smooth transition effect */
}

.cardDisease img:hover {
  scale: 105%; /* Reduce opacity on hover */
}
.contain {
  position: relative;
  width: 100%;
}
.img-contain {
  width: 100%;
  filter: blur(5px)!important; /* Apply a blur effect */
}

.texti{
  position: absolute;
  top: 52%;
  left: 27.5%; 
  font-size: 70px;
  font-weight: bolder;
  transform: translate(-50%, -50%);
}
.btn {
  position: relative;
  background-color: rgba(0, 0, 0, 1) !important; /* Semi-transparent black background */
  color: white !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1; /* Ensures button is above the image */
  font-weight: bolder;
  font-size: 20px;
}

.dropdown
{
  display: none;
}

.row{
  margin-right: inherit;
}


/*------------------------------MOBILE VIEW CSS--------------------------------------*/

@media only screen and (max-width: 600px) {

  body {
    overflow-x: hidden;
  }
  
  .docimage{
width: 320px;
  }
  .docart{
    padding: 10px;
  }
 .header
 {
   font-size: 13px;
   font-weight: 800;
   padding-left: 22px;
   padding-bottom: 4px;
 }

 .texti{
  position: absolute;
  top: 47%;
  left: 29%; 
  font-size: 20px;
  font-weight: bolder;
  transform: translate(-50%, -50%);
}

.btn {
  position: absolute;
  transform: translate(-20%, -12%);
  background-color: rgba(0, 0, 0, 1); /* Semi-transparent black background */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1; /* Ensures button is above the image */
  margin-left:37px;
  font-weight:500;
  font-size:15px ;
}

.logo-img
{
  width:55px;
  height: 55px;
}

.dropdown
{
  position:absolute;
  display: flex;
  justify-content: flex-end;
  padding-top:15px;
  width:158px;
}
/* CSS targeting anchor tags with class "custom-link" */
.custom-link {
  text-decoration: none;
  color: inherit;
}
.custom-link:hover{
  text-decoration: none;
  color: white; 
}
.dropdownmenu
{
  background: white;
  position: absolute;
  z-index:1;
  width:145px;
  border-radius:5px;
  top:110%;
  left:10%;
}

.cardDisease{
  border-radius: 20px;
  width:250px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  margin:15px;
}

.headers{
  font-size: 33px;
  font-weight: bolder;
  text-align: center;
  padding: 15px;
  }
  .headersnew{
    font-size: 33px;
    font-weight: bold;
    text-align: center;
    }

  .listborder
  {
    border-bottom: 1px solid #000; /* Add border bottom to each list item */
  padding-bottom: 5px; 
  }
  .sticky-header {
    position: sticky;
    top: 0; /* Stick the header to the top of the viewport */
    z-index: 1000; /* Optional: Adjust the z-index to ensure the header is above other content */
   /* Optional: Add background color to the header */
   
  }
  .diseaseContainer{ 
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    }
    .imgi{
      margin:5px;
      width:300px;
    height: 240px;
    }
    .marquee-container {
      overflow: hidden;
    }
    
    .marquee {
      display: flex;
      margin: 30px;
      min-width: 2400px;
      animation: marqueeAnimation 20s linear infinite;
    }
    .marqueei {
      display: flex;
      margin: 30px;
      min-width: 2400px;
      animation: marqueeiAnimation 20s linear infinite;
    }
    .cardDisease{
      display: inline-block;
      border-radius: 20px;
      width:360px;
      margin-right: 30px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    }
    @keyframes marqueeAnimation {
      0% {
        transform: translateX(100);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    @keyframes marqueeiAnimation {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
}
